import request from '../_api/request'

const state = {
  commerceOrder: {
    data: [],
    total: 0
  },
  commerceProduct: {
    data: [],
    total: 0
  }
}

const mutations = {
  SET_COMMERCE_ORDER (state, v) {
    state.commerceOrder.data = v.data || []
    state.commerceOrder.total = parseInt(v.total) || 0
  },
  SET_COMMERCE_PRODUCT (state, v) {
    state.commerceProduct.data = v.data || []
    state.commerceProduct.total = parseInt(v.total) || 0
  }
}

const actions = {
  async ORDER_ITEM_SEARCH (context, oid) {
    return request.get('commerce/order/' + (oid || 0)).then((res) => {
      return res
    }).catch((e) => {
      return {
        status: false,
        data: e
      }
    })
  },
  async ORDER_SEARCH (context, query) {
    return request.get('commerce/order' + (query || '')).then((res) => {
      return res
    }).catch((e) => {
      return {
        status: false,
        data: e
      }
    })
  },
  async ORDER_INVOICE (context, invoiceHash) {
    return request.get(`commerce/invoice/${invoiceHash || '-'}`).then((res) => {
      return res
    }).catch((e) => {
      return {
        status: false,
        data: e
      }
    })
  },
  async ORDER_INVOICE_CONFIRM (context, payload) {
    return request.post(`commerce/invoice/${payload.invoice_hash}`, payload.data).then((res) => {
      return res
    }).catch((e) => {
      return {
        status: false,
        data: e
      }
    })
  },
  async SET_COMMERCE_TTK (context, payload) {
    return request.post('logistic/commerce-ttk', payload).then((res) => {
      return res
    }).catch((e) => {
      return {
        status: false,
        data: e
      }
    })
  },
  async ORDER_CREATE (context, params) {
    return request.post('commerce/order', params).then((res) => {
      return res
    }).catch((e) => {
      return {
        status: false,
        data: e
      }
    })
  },
  async PRODUCT_SEARCH (context, query) {
    return request.get('commerce/product' + (query || '')).then((res) => {
      return res
    }).catch((e) => {
      return {
        status: false,
        data: e
      }
    })
  },
  async PRODUCT_CREATE (context, params) {
    return request.post('commerce/product', params).then((res) => {
      return res
    }).catch((e) => {
      return {
        status: false,
        data: e
      }
    })
  },
  async CUSTOMER_SEARCH (context, query) {
    return request.get('commerce/customer' + (query || '')).then((res) => {
      return res
    }).catch((e) => {
      return {
        status: false,
        data: e
      }
    })
  },
  async CUSTOMER_CREATE (context, params) {
    return request.post('commerce/customer', params).then((res) => {
      return res
    }).catch((e) => {
      return {
        status: false,
        data: e
      }
    })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
