import request from '../_api/request'

const state = {
}

const mutations = {
}

const actions = {
  async DISPATCH_GET (context, q) {
    return await request.get('logistic/dispatch' + (q || ''))
      .then(res => res)
      .catch((r) => {
        return {
          status: false
        }
      })
  },
  async DISPATCH_PROCESS (context, params) {
    return await request.post('logistic/dispatch', params)
      .then(res => res)
      .catch((r) => {
        return {
          status: false
        }
      })
  },
  async DISPATCH_DELETE (context, id) {
    return await request.delete('logistic/dispatch/' + (id || 0))
      .then(res => res)
      .catch((r) => {
        return {
          status: false
        }
      })
  },
  async MANIFEST_GET (context, q) {
    return await request.get('logistic/manifest' + (q || ''))
      .then(res => res)
      .catch((r) => {
        return {
          status: false
        }
      })
  },
  async MANIFEST_LIST_GET (context, manifestID) {
    return await request.get('logistic/manifest-list/' + (manifestID || '0'))
      .then(res => res)
      .catch((r) => {
        return {
          status: false
        }
      })
  },
  async MANIFEST_PROCESS (context, params) {
    return await request.post('logistic/manifest', params)
      .then(res => res)
      .catch((r) => {
        return {
          status: false
        }
      })
  },
  async MANIFEST_DELETE (context, id) {
    return await request.delete('logistic/manifest/' + (parseInt(id) || 0))
      .then(res => res)
      .catch((r) => {
        return {
          status: false
        }
      })
  },
  async PICKUP_GET (context, q) {
    return await request.get('logistic/pickup' + (q || ''))
      .then(res => res)
      .catch((r) => {
        return {
          status: false
        }
      })
  },
  async PICKUP_PROCESS (context, params) {
    return await request.post('logistic/pickup', params)
      .then(res => res)
      .catch((r) => {
        return {
          status: false
        }
      })
  },
  async SHIPPING_GET (context, q) {
    const r = await request.get('logistic/shipping' + (q || ''))
      .then(res => res)
      .catch((r) => {
        return {
          status: false
        }
      })
    return r
  },
  async LAST_UPDATE (context, p) {
    return await request.get(`logistic/last_update?project=${p?.project || 0}`)
      .then(res => res)
      .catch((r) => {
        return {
          status: false
        }
      })
  },
  async CONFIRM_DELIVERED (context, params) {
    return await request.post('logistic/confirm-delivered', params)
      .then(r => r)
      .catch((r) => {
        return {
          status: false
        }
      })
  },
  async REPORT_GET (context, pid) {
    return await request.get(`logistic/project-report/${pid || 0}`)
      .then(res => res)
      .catch((r) => {
        return {
          status: false
        }
      })
  },
  async DOCUMENT_GET (context, q) {
    return await request.get(`upload/documentation${q || ''}`)
      .then(res => res)
      .catch((r) => {
        return {
          status: false
        }
      })
  },
  async DOCUMENT_PROCESS (context, params) {
    return await request.post('upload/documentation', params)
      .then(r => r)
      .catch((r) => {
        return {
          status: false
        }
      })
  },
  async REPORT_SHIPPING_GET (context, q) {
    return await request.get(`logistic/project-shipping-report${q}`)
      .then(res => res)
      .catch((r) => {
        return {
          status: false
        }
      })
  },
  async PROJECT_GET (context, q) {
    return await request.get(`logistic/project${q}`)
      .then(res => res)
      .catch((r) => {
        return {
          status: false
        }
      })
  },
  async PROJECT_PROCESS (context, params) {
    return await request.post('logistic/project', params)
      .then(res => res)
      .catch((r) => {
        return {
          status: false
        }
      })
  },
  async SHIPPING_PROCESS (context, params) {
    return await request.post('logistic/shipping', params)
      .then(res => res)
      .catch((r) => {
        return {
          status: false
        }
      })
  },
  async SHIPPING_PROCESS_BULK (context, params) {
    return await request.post('logistic/import-bulk', params)
      .then(res => res)
      .catch((r) => {
        return {
          status: false
        }
      })
  },
  async SHIPPING_PRICING_GET (context, data) {
    return await request.get('logistic/pricing/' + (data.vendor || 0) + (data.q || ''))
      .then(res => res)
      .catch((r) => {
        return {
          status: false
        }
      })
  },
  async SHIPPING_PRICING_DELETE (context, params) {
    return await request.delete('logistic/pricing/' + params.id)
      .then(res => res)
      .catch((r) => {
        return {
          status: false
        }
      })
  },
  async SHIPPING_PRICING_PROCESS (context, params) {
    return await request.post('logistic/pricing/' + params.vendor, params)
      .then(res => res)
      .catch((r) => {
        return {
          status: false
        }
      })
  },
  async TTK_ACTION (context, params) {
    return await request.post('logistic/ttk-action', params)
      .then(res => res)
      .catch((r) => {
        return {
          status: false
        }
      })
  },
  async SHIPPING_PRODUCT_GET (context) {
    return await request.get('logistic/product')
      .then(res => res)
      .catch((r) => {
        return {
          status: false
        }
      })
  },
  async SHIPPING_PRODUCT_PROCESS (context, params) {
    return await request.post('logistic/product', params)
      .then(res => res)
      .catch((r) => {
        return {
          status: false
        }
      })
  },
  async SHIPPING_PLAN_GET (context, data) {
    return await request.get('logistic/plan/' + (data.master || 0) + (data.q || ''))
      .then(res => res)
      .catch((r) => {
        return {
          status: false
        }
      })
  },
  async SHIPPING_PLAN_PROCESS (context, params) {
    return await request.post('logistic/plan/' + params.master, params)
      .then(res => res)
      .catch((r) => {
        return {
          status: false
        }
      })
  },
  async PAYMENT_GET (context, q) {
    return await request.get('logistic/payment' + (q || ''))
      .then(res => res)
      .catch((r) => {
        return {
          status: false
        }
      })
  },
  async PAYMENT_PROCESS (context, params) {
    return await request.post('logistic/payment', params)
      .then(res => res)
      .catch((r) => {
        return {
          status: false
        }
      })
  },
  async SHIPPING_PLAN_UPDATE (context, params) {
    return await request.put('logistic/plan/' + params.id + '/' + params.status + '/' + params.status_plan, { notes: params.notes })
      .then(res => res)
      .catch((r) => {
        return {
          status: false
        }
      })
  },
  async SHIPPING_PLAN_DELETE (context, id) {
    return await request.delete('logistic/plan/' + id)
      .then(res => res)
      .catch((r) => {
        return {
          status: false
        }
      })
  },
  async ACCURATE_BRANCH_GET (context) {
    return await request.get('accurate/branch')
      .then(res => res)
      .catch((r) => {
        return {
          status: false
        }
      })
  },
  async ACCURATE_SALES_GET (context, query) {
    // page, pageSize, sort, branchId
    return await request.get('accurate/sales' + (query || ''))
      .then(res => res)
      .catch((r) => {
        return {
          status: false
        }
      })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
