import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'
import colors from 'vuetify/es5/util/colors'
import VueQrcode from '@chenfengyuan/vue-qrcode'

Vue.use(Vuetify)
Vue.component(VueQrcode.name, VueQrcode)

export default new Vuetify({
  customVariables: ['@/scss/variables.scss'],
  theme: {
    dark: false,
    themes: {
      light: {
        primary: '#cf7600',
        secondary: '#024434',
        accent: '#F0F0F0',
        info: colors.teal.lighten1,
        warning: colors.amber.base,
        error: colors.deepOrange.accent4,
        success: colors.green.accent3
      }
    }
  },
  treeShake: true,
  defaultAssets: {
    font: {
      family: 'Poppins'
    }
  }
})
