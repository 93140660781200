import request from '../_api/request'

const state = {
  event: [],
  media: [],
  banner: []
}

const mutations = {
  SET_EVENT (state, v) {
    state.event = v || []
  },
  SET_MEDIA (state, v) {
    state.media = v || []
  },
  SET_BANNER (state, v) {
    state.banner = v || []
  }
}

const actions = {
  async EVENT_REGISTER_PROCESS (context, params) {
    return await request.post('store/event-register', params).then(res => res).catch((e) => {
      return {
        status: false,
        data: e
      }
    })
  },
  async EVENT_REGISTER_GET (context, eventID) {
    return request.get('store/event-register/' + (eventID || '0')).then(res => res).catch((e) => {
      return {
        status: false,
        data: e
      }
    })
  },
  async EVENT_GET (context, query) {
    return request.get('store/event' + (query || '')).then((res) => {
      if (res.status) {
        context.commit('SET_EVENT', res.data.data || [])
      } else {
        context.commit('SET_EVENT', [])
      }
      return res
    }).catch((e) => {
      return {
        status: false,
        data: e
      }
    })
  },
  async EVENT_PROCESS (context, params) {
    return request.post('store/event', params).then((res) => {
      return res
    }).catch((e) => {
      return {
        status: false,
        data: e
      }
    })
  },
  async MEDIA_GET (context, query) {
    return request.get('store/media' + (query || '')).then((res) => {
      if (res.status) {
        context.commit('SET_MEDIA', res.data.data || [])
      } else {
        context.commit('SET_MEDIA', [])
      }
      return res
    }).catch((e) => {
      return {
        status: false,
        data: e
      }
    })
  },
  async MEDIA_PROCESS (context, params) {
    return request.post('store/media', params).then((res) => {
      return res
    }).catch((e) => {
      return {
        status: false,
        data: e
      }
    })
  },
  async BANNER_GET (context, query) {
    return request.get('feature/banner' + (query || '')).then((res) => {
      if (res.status) {
        context.commit('SET_BANNER', res.data.data || [])
      } else {
        context.commit('SET_BANNER', [])
      }
      return res
    }).catch((e) => {
      return {
        status: false,
        data: e
      }
    })
  },
  async BANNER_PROCESS (context, params) {
    return request.post('feature/banner', params).then((res) => {
      return res
    }).catch((e) => {
      return {
        status: false,
        data: e
      }
    })
  },
  async VOUCHER_GET (context, q) {
    return await request.get('/voucher' + (q || ''))
      .then(res => res)
      .catch((err) => {
        return {
          status: false,
          data: err
        }
      })
  },
  async VOUCHER_PROCESS (context, voucher) {
    return await request.post('/voucher', voucher)
      .then(res => res)
      .catch((err) => {
        return {
          status: false,
          data: err
        }
      })
  },
  async VOUCHER_UPDATE (context, voucher) {
    return await request.put('/voucher/' + voucher.id, voucher)
      .then(res => res)
      .catch((err) => {
        return {
          status: false,
          data: err
        }
      })
  },
  async VOUCHER_DELETE (context, voucherID) {
    return await request.delete('/voucher/' + voucherID)
      .then(res => res)
      .catch((err) => {
        return {
          status: false,
          data: err
        }
      })
  },
  async CUSTOMER_POINT_GET (context, q) {
    return await request.get('/user/customer-point' + (q || ''))
      .then(res => res)
      .catch((err) => {
        return {
          respose: false,
          data: err
        }
      })
  },
  async CUSTOMER_POINT_PROCESS (context, params) {
    return await request.post('/user/customer-point', params)
      .then(res => res)
      .catch((err) => {
        return {
          respose: false,
          data: err
        }
      })
  },
  async TOUR_GUIDE_GET (context, q) {
    return await request.get('/user/tour-guide-selfie' + (q || ''))
      .then(res => res)
      .catch((err) => {
        return {
          respose: false,
          data: err
        }
      })
  },
  async TOUR_GUIDE_PROCESS (context, params) {
    return await request.post('/user/tour-guide-selfie', params)
      .then(res => res)
      .catch((err) => {
        return {
          respose: false,
          data: err
        }
      })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
